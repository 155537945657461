html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  #root {
    width: 100%;
    height: 100%;
  }


  .app {
    width: 100%;
    height: 100%;

    .route-content,
    .route-content--without-sidebar {
      width: 100%;
      height: 100%;
    }

    .flight-nav__header {
      z-index: 3;
    }

    .fligt-nav {
      .flight-nav__header__actionables {
        display: flex;
        flex: 1;
      }

      .flight-nav__header__project__environment {
        transform: none;
      }

      .flight-nav__header {
        button.flight-button {
          display: none;
        }
      }
    }

    &__nav-actionables {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    // Do not apply padding / margin inside this block.
    // Padding & Margin should be applied to individual page
    &__main {
      flex: 1;
      height: 100%;
      overflow: scroll;
    }
  }

  // This is needed to render above the ControlBar component
  .flight-modal-wrapper {
    z-index: 100;
  }

}

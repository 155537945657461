

.no-access {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  .header {
    padding: 10px;
    background: rgb(218, 62, 62);
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 15px;
  }
  .description {
    text-align: center;
  }
}
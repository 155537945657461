@import 'src/styles/variables';

.schedule-inline {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 30px;
  top: 0;
  padding: 10px;
  background-color: $primary-white;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 0 0 4px 4px;

  &__name {
    color: $neutral-color-600;
    margin-left: 10px;
  }

  &__link {
    display: flex;
    flex-direction: row;
    color: $link-color;
    font-weight: bold;
    margin-left: 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $link-hover-color;
    }
  }

  &__manual-activ {
    color: $neutral-color-600;
    margin-right: 15px;
  }

}
@import 'src/styles/mixins';
@import 'src/styles/variables';

.flow-box {
  display: flex;

  &__card-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &__button {
      display: flex;
      flex-direction: column;
      padding: $spacer-2xlarge;
      background-color: $primary-white;
      border-radius: $border-radius*2;
      border: $border-width * 2 solid $border-color;
      width: 350px;
      cursor: pointer;
      margin: 0 $spacer-2xlarge;
      box-sizing: border-box;
      box-shadow: none;
      transition: all 300ms;

      &.error {
        border: $border-width * 2 solid rgb(240, 117, 79);
        box-sizing: border-box;
      }

      &:hover, &:focus {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        transition: all 300ms;
      }

      &.not-clickable {
        cursor: default;
        &:hover, &:focus {
          box-shadow: none;
        }
      }
    }

    &__card {
      position: initial !important;
      width: 100%;

      &:focus {
        outline: none;
      }

      .flight-card__actionables {}

      &__step-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__type-box {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        &__icon {
          margin-right: 15px;
          width: 30px;
          height: 30px;
        }

        &__question {
          width: 25px;
          height: 25px;
          fill: $neutral-color-500
        }
      }

      &__step-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 10px;

        &__title {
          font-weight: bold;
          font-size: 90%;
          margin: 40px 0 20px 0;
        }

        &__content {
          margin-bottom: 35px;
          margin-top: 25px;
          padding: 5px;
        }

        &__descr {
          padding: 10px 0px;
          color: $neutral-color-600;
          font-size: 15px;
          .anyone {
            &__heading {
              color: #1E2B4A;
              font-size: 20px;
              font-weight: normal;
            }
            p {
              color: $neutral-color-600;
              font-size: 14.5px;
            }
          }
        }

        &__plugins {
          font-size: 14px;
          display: flex;
          margin-top: 10px;

          &__plugin-left {
            padding-right: 3px;
          }
        }

        &__placeholder {
          margin: 20px 0;
          font-size: 90%;
          color: $neutral-color-500;
        }
      }
    }

    &__arrow {
      margin-top: 75px;
    }
  }
}
@import 'src/styles/variables';

.ao-templated-experiences-index {

  display: flex;

  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40px 80px;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 40px;
  }

  &__create-button {
    padding: 0 40px;
  }

  &__title {
    font-size: 150%;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__subtitle {
    color: $neutral-color-700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__status {
    width: fit-content;
    padding: 2px 5px;
    border-radius: $border-radius;
    text-transform: uppercase;

    &.draft {
      color: $neutral-color-700;
      background-color: lighten($neutral-color-700, 50%);
    }
    &.active {
      color: $green-700;
      background-color: lighten($green-400, 50%);
    }
    &.inactive {
      color: $red-500;
      background-color: lighten($red-600, 50%);
    }
    &.scheduled {
      color: $teal-700;
      background-color: lighten($teal-500, 50%);
    }
  }

  &__dropdown {
    z-index: unset;

    .flight-overflow-menu {
      z-index: unset;
    }

    .flight-overflow-menu__dropdown-list--opened {
      z-index: 1;
    }
  }

}

.analytics-performance-chart {
  flex: 1;
  border: 1px solid #ddd;
  background: white;
  height: 500px;
  width: 100%;
  box-sizing: border-box;
  padding: 50px;
  display: flex;
  flex-direction: column;
  .top {
    display: flex;
    position: relative;
    justify-content: space-between;
    .day-range-picker {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
  p {
    margin: 0;
    padding: 0;
    &.headline {
      font-size: 23px;
    }
  }
}
@import 'src/styles/variables';
@import "src/styles/mixins";

$bar-height: 80px;

.control-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  overflow: scroll;
  height: 100%;

  &__header {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    height: $bar-height;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    box-sizing: border-box;
    background: white;
    z-index: 2;

    &-left {
      padding-left: 20px;
      flex: 2;
      input {
        min-width: 150px;
        width: 80%;
        outline-style: none;
        box-shadow: none;
        border-color: transparent;
        font-size: 25px;
      }
    }
    &-right {
      padding-right: 20px;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      button {
        margin-right: 10px;
      }
    }
  }
  &__main {
    margin-top: $bar-height;
    display: flex;
    flex: 1;
  }
  &__status {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;

    &__text {
      margin-left: 8px;
      text-transform: uppercase;
    }
  }
}

.developer-hub {
  .flight-text-input-wrapper {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  &__section {
    padding: 10px;
  }
  .flight-button {
    margin-top: 10px;
  }
}
@import 'src/styles/variables';

.schedule-edit-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //min-height: 250px;
  overflow: visible;
  padding-bottom: 20px;

  &__content {
    margin-bottom: 20px;
  }

  &__runtime {
    font-size: 16px;
    font-weight: bold;
    color: $neutral-color-600;
    margin: 10px 0;
  }

  &__start, &__end {
    display: flex;
    flex-direction: column;
  }

  &__end {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  &__label {
    font-weight: bold;
    margin: 10px 20px 10px 0;
  }

  &__select {
    min-width: 200px;
    margin-left: 20px;

    label {
      min-width: 250px;
    }
    button {
      text-align: left;
    }
  }

  &__start-date-box {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  &__datepicker {

    input {
      padding: 12px 0 13px 10px;
      cursor: pointer;
      min-width: 250px;
    }
  }

  &__add-end {
    color: $link-color;
    font-weight: bold;
    margin-top: 20px;
  }

  &__remove-icon {
    margin-left: 20px;
  }

  &__timezone {
    margin-top: 20px;
  }

  &__comment {
    font-size: 90%;
    margin-top: 5px;
    color: $neutral-color-600;
  }

  &__manual-activ {
    font-weight: bold;
    margin: 15px 0;
  }

}

.flight-modal-wrapper .flight-modal .flight-modal__content {
  overflow: visible;
}
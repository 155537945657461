.create-edit-push {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 60px;
  padding: 10px;

  &__heading {
    font-weight: bold;
    margin: 10px 0 10px 0;
  }

  &__input {
    margin-top: 10px;
  }

}
@import 'styles/variables';

$padding-row: 25px;
$min-height: 90px;

.audience-guide {
  display: flex;
  align-items: center;
  height: 300px;
  &__image {
    width: 300px;
    display: flex;
    justify-content: center;
    img {
      width: 200px;
    }
  }
  &__detail {
    width: 600px;
    padding: 20px;
    p {
      font-size: 16px;
    }
  }
}

.audience-edit {
  min-height: 300px;
  padding: 5px;
  margin-top: 20px;
  width: 100%;
  &__header {
    display: flex;
    padding-left: $padding-row;
    align-items: center;
    p {
      margin-left: 10px;
    }
  }

  &__main {
    &-row.card {
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      margin-top: 15px;
      .remove {
        visibility: hidden;
        position: absolute;
        top: 15px;
        right: 15px;
        opacity: 0.5;
        &:hover {
          opacity: 1.0
        }
      }
      &:hover {
        border: 1px solid #ddd;
        // box-shadow: 1px 1px gray;
        border-radius: 5px;
        box-sizing: border-box;
        & .remove {
          visibility: visible;
        }
      }
    }
    &-row {
      padding: $padding-row;
      margin-top: 10px;
      display: flex;
      align-items: center;
      width: 700px;
      border: 1px solid white;
      .left {
        display: flex;
        align-items: flex-start;
        min-height: $min-height;
        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background: #D5E4FE;
          color: #0851DF;
          font-weight: bold;
        }
      }
      .right {
        min-height: $min-height;
        margin-left: 15px;
        &-bottom {
          margin-top: 10px;
          .flight-select:nth-child(1) {
            margin-right: 5px;
          }
          .row {
            display: flex;
          }
        }
      }
    }
    .filler {
      height: 300px;
    }
  }
}

// override flybits scss
.plugin-searchbar {
  .flight-dropdown__trigger {
    // Remove this if this bug is fixed in design system project.
    .flight-text-input-wrapper {
      width: 100% !important;
    }
  }
}

.primary {
  margin: 0;
  margin-top: 10px;
  color: $primary-blue;
}

.loading {
  margin-top: 20px;
}

.audience__button {
  align-self: center;
  margin: 10px;
  border: 1px solid $primary-blue;
  padding: 10px 15px;
  border-radius: 30px;
  color: $primary-blue;

  &:hover {
    background-color: lighten($primary-blue, 50);
  }
}

.list-select-modal {
  width: 100%;
  &__content {
    display: flex;
    flex-direction: column;
    &-item {
      border: 1.5px solid rgb(221, 222, 224);
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 5px;
      background: white;
      cursor: pointer;
      &.selected {
        box-sizing: border-box;
        border: 1.5px solid #4992FD;
      }
    }
  }
  &__header {
    display: flex;
    &-icon {
      width: 30px;
      margin-right: 10px;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
}

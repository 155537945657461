@import 'src/styles/variables';

.incomplete-counter {
  position: absolute;
  right: 30px;
  top: 35px;
  background: none;
  border: none;
  padding: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: $neutral-color-500;
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: $neutral-color-500;
  }

  &.error {
    color: $secondary-red;
    fill: $secondary-red;
    svg {
      fill: $secondary-red;
    }
  }

}
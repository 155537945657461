.confirm-modal {
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    font-size: 120%;
    margin: 20px;
  }

  &__header {
    display: flex;

    &-icon {
      margin-right: 10px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 10px;
    }
  }
}

@import 'src/styles/variables';

.preview-content {
  position: initial;
  width: 300px;
  min-height: 350px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 20px;

  &__image {
    width: 100%;
    margin: 20px 0;
  }

  &__preview-body {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 65px);
    margin-right: 20px;
    margin-left: 20px;
    background-color: white;
    border-radius: 15px;
    padding: 12px;
    align-self: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: left;

    &__header {
      font-size: 10px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__title {
      font-weight: bold;
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }

    &__body {
      width: 100%;
      font-size: 11px;
      margin: 5px 0 0 0;
      line-height: 1.3em;
      max-height: 55px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    &__button {
      align-self: center;
      margin: 10px;
      border: 1px solid $primary-blue;
      padding: 10px 15px;
      border-radius: 30px;
      color: $primary-blue;

      &:hover {
        background-color: lighten($primary-blue, 50);
      }
    }

    &__your-app-icon {
      margin-right: 5px;
    }
  }
}
